import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { IconMail, IconCalendar } from '@tabler/icons-react';
import TimelineMail from './TimelineMail';
import TimelineCalendar from './TimelineCalendar';
import TimelineSlack from './TimelineSlack';
import TimelineDiscord from './TimelineDiscord';
import { Email, CalendarEvent, SlackMessage, DiscordMessage, Event } from './types';
import { formatTimestamp } from './TimelineData';

const StyledTimelineItem = styled(TimelineItem)(({ theme }) => ({
  // Add any custom styles if needed
}));

type TimelineItemProps = {
  event: Event;
  isBelowMdScreen: boolean;
  index: number;
  displayedEventsLength: number;
};

const CustomTimelineItem: React.FC<TimelineItemProps> = ({
  event,
  isBelowMdScreen,
  index,
  displayedEventsLength
}) => {
  return (
    <StyledTimelineItem>
      <TimelineOppositeContent>
        <div className="feedback-section">
          <textarea className="h-full w-full" placeholder="Add a comment..." />
        </div>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot
          color={
            event.type === 'email'
              ? 'primary'
              : event.type === 'calendar'
              ? 'error'
              : event.type === 'slack'
              ? 'info'
              : 'secondary'
          }
          style={{ backgroundColor: event.type === 'discord' ? 'white' : undefined }}
        >
          {event.type === 'email' ? (
            <IconMail size={20} />
          ) : event.type === 'calendar' ? (
            <IconCalendar size={20} />
          ) : event.type === 'slack' ? (
            <img
              src="/images/logos/slack.png"
              alt="Slack"
              width={20}
              style={{
                minWidth: '20px',
                height: '20px',
                display: 'block',
              }}
            />
          ) : event.type === 'discord' ? (
            <img
              src="/images/logos/discord.png"
              alt="Discord"
              width={20}
              style={{
                minWidth: '20px',
                height: '20px',
                display: 'block',
              }}
            />
          ) : null}
        </TimelineDot>
        {index < displayedEventsLength - 1 && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        {isBelowMdScreen && (
          <Typography variant="caption" component="div" className="mbs-5">
            {formatTimestamp(
              (event as Email).timestamp_epoc ||
              (event as CalendarEvent).timestamp_epoc ||
              (event as SlackMessage).timestamp_epoc ||
              (event as DiscordMessage).timestamp_epoc ||
              'Unknown Date'
            )}
          </Typography>
        )}
        {event.type === 'email' ? (
          <TimelineMail event={event as Email} isBelowMdScreen={isBelowMdScreen} />
        ) : event.type === 'calendar' ? (
          <TimelineCalendar event={event as CalendarEvent} isBelowMdScreen={isBelowMdScreen} />
        ) : event.type === 'slack' ? (
          <TimelineSlack event={event as SlackMessage} isBelowMdScreen={isBelowMdScreen} />
        ) : event.type === 'discord' ? (
          <TimelineDiscord event={event as DiscordMessage} isBelowMdScreen={isBelowMdScreen} />
        ) : null}
      </TimelineContent>
    </StyledTimelineItem>
  );
};

export default CustomTimelineItem;