import React, { useEffect, useState, useCallback } from 'react';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import MuiTimeline from '@mui/lab/Timeline';
import { styled } from '@mui/material/styles';
import { Event, Contact, EventType } from './types'; // Import EventType
import { TimelineData } from './TimelineData';
import CustomTimelineItem from './CustomTimelineItem';
import TimelineFilter from './TimelineFilter';

const Timeline = styled(MuiTimeline)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  '& .MuiTimelineItem-root:nth-of-type(even) .MuiTimelineContent-root': {
    textAlign: 'left',
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiTimelineItem-root': {
      width: '100%',
      '&:before': {
        display: 'none',
      },
    },
  },
  paddingTop: '64px',
}));

const TimelineComponent: React.FC = () => {
  const theme = useTheme();
  const isBelowMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [events, setEvents] = useState<Event[]>([]);
  const [displayedEvents, setDisplayedEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const [selectedEventTypes, setSelectedEventTypes] = useState<EventType[]>([]); // Update to EventType[]
  const eventCount = 10;

  const getFilteredEvents = useCallback(() => {
    if (selectedContacts.length === 0 && selectedEventTypes.length === 0) {
      return events;
    }
    const selectedContactNames = selectedContacts.flatMap(contact => contact.name);
    
    return events.filter(event => {
      if (selectedEventTypes.length > 0 && !selectedEventTypes.includes(event.type as EventType)) {
        return false;
      }

      switch (event.type) {
        case 'email':
          return event.from.some(contact => 
            Array.isArray(contact.name) && contact.name.some(name => selectedContactNames.includes(name))
          ) || 
          event.to.some(contact => 
            Array.isArray(contact.name) && contact.name.some(name => selectedContactNames.includes(name))
          );
        case 'calendar':
          return selectedContactNames.includes(event.organizer.name) || 
          event.attendees.some(contact => 
            Array.isArray(contact.name) && contact.name.some(name => selectedContactNames.includes(name))
          );
        case 'slack':
          return selectedContactNames.includes(event.from.name) || 
                 selectedContactNames.includes(event.to.name);
        case 'discord':
          const fromNames = Array.isArray(event.from) 
            ? event.from.flatMap(contact => 
                Array.isArray(contact.name) ? contact.name : []
              )
            : [];
          const toNames = Array.isArray(event.to) 
            ? event.to.flatMap(contact => 
                Array.isArray(contact.name) ? contact.name : []
              )
            : [];
          
          return fromNames.some(name => selectedContactNames.includes(name)) || 
                 toNames.some(name => selectedContactNames.includes(name));
        default:
          return false;
      }
    });
  }, [events, selectedContacts, selectedEventTypes]);

  const loadMoreEvents = useCallback(() => {
    if (loading || displayedEvents.length >= getFilteredEvents().length) return;

    setLoading(true);
    setDisplayedEvents((prevEvents) => {
      const newEvents = getFilteredEvents().slice(prevEvents.length, prevEvents.length + eventCount);
      setLoading(false);
      return [...prevEvents, ...newEvents];
    });
  }, [displayedEvents, loading, getFilteredEvents]);

  useEffect(() => {
    const loadEventData = async () => {
      const loadedEvents = await TimelineData.loadEvents();

      const sortedEvents = loadedEvents.sort((a, b) => 
        (b.timestamp_epoc ?? 0) - (a.timestamp_epoc ?? 0)
      );

      setEvents(sortedEvents);
      setDisplayedEvents(sortedEvents.slice(0, eventCount));
    };

    loadEventData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollableHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;
      const clientHeight = window.innerHeight;

      if (scrollTop + clientHeight >= scrollableHeight - 5) {
        loadMoreEvents();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [loadMoreEvents]);

  useEffect(() => {
    setDisplayedEvents([]);
    setTimeout(() => {
      const filteredEvents = getFilteredEvents();
      setDisplayedEvents(filteredEvents.slice(0, eventCount));
      window.scrollTo(0, 0);
    }, 0);
  }, [selectedContacts, selectedEventTypes, getFilteredEvents]);

  return (
    <>
      <TimelineFilter 
        selectedContacts={selectedContacts} 
        onSelectContacts={setSelectedContacts}
        selectedEventTypes={selectedEventTypes} // Pass the selected event types
        onSelectEventTypes={setSelectedEventTypes} // Pass the event types selection handler
      />
      <Timeline position={isBelowMdScreen ? 'left' : 'left'}>
        {displayedEvents.map((event, index) => (
          <CustomTimelineItem
            key={index}
            event={event}
            isBelowMdScreen={isBelowMdScreen}
            index={index}
            displayedEventsLength={displayedEvents.length}
          />
        ))}
      </Timeline>
    </>
  );
};

export default TimelineComponent;