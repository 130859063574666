import React from 'react';
import { Card, CardContent, Typography, Avatar, Divider } from '@mui/material';
import { SlackMessage } from './types';
import emoji from 'emoji-dictionary';

type TimelineSlackProps = {
  event: SlackMessage;
  isBelowMdScreen: boolean;
};

const parseSlackMessage = (message: string) => {
  const lines = message.split('\n');
  return lines.map((line, index) => (
    <Typography
      key={index}
      variant="body2"
      component="div"
      style={{
        paddingLeft: line.startsWith('> ') ? 20 : 0,
        borderLeft: line.startsWith('> ') ? '2px solid #ddd' : 'none',
        marginLeft: line.startsWith('> ') ? 10 : 0,
        color: line.startsWith('> ') ? '#555' : 'inherit',
      }}
    >
      {line.split(' ').map((word, i) => (
        <React.Fragment key={i}>
          {emoji.getUnicode(word) || word}{' '}
        </React.Fragment>
      ))}
    </Typography>
  ));
};

const TimelineSlack: React.FC<TimelineSlackProps> = ({ event, isBelowMdScreen }) => (
  <Card className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root border mui-gkyr07 slack">
    <CardContent className="MuiCardContent-root flex is-full gap-4 mui-2ggi8u">
      <Avatar
        className="MuiAvatar-root MuiAvatar-circular mui-1o9xkzw"
        src={`/images/avatars/${event.from.avatar}`}
        alt={event.from.name}
        style={{ width: isBelowMdScreen ? 70 : 70, height: isBelowMdScreen ? 70 : 70 }}
      />
      <div className="flex items-center justify-between flex-wrap grow gap-x-4 gap-y-2">
        <div className="flex flex-col">
          <Typography variant="body1" className="mui-cb9j9j"><strong>From: </strong>{event.from.name}</Typography>
          <Typography variant="body2" className="mui-cb9j9j"><strong>To:</strong> {event.to.name}</Typography>
        </div>
        <div className="flex items-center gap-4">
          <Typography variant="body1" className="mui-miu7e">
            {new Date(event.timestamp_epoc * 1000).toLocaleString()}
          </Typography>
        </div>
      </div>
    </CardContent>
    <Divider className="MuiDivider-root MuiDivider-fullWidth mui-zdzcbx" />
    <CardContent className="MuiCardContent-root mui-2ggi8u" style={{ maxHeight: '300px', overflowY: 'auto' }}>
      <div className="text-textSecondary styles_message__LkVz3 float-left">
        {parseSlackMessage(event.message)}
      </div>
    </CardContent>
  </Card>
);

export default TimelineSlack;