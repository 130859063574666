import React from 'react';
import { Card, CardContent, Typography, Avatar, Box } from '@mui/material';
import { DiscordMessage } from './types';

type TimelineDiscordProps = {
  event: DiscordMessage;
  isBelowMdScreen: boolean;
};

const parseDiscordMessage = (message: string) => {
  const lines = message.split('\n');
  return lines.map((line, index) => (
    <Typography
      key={index}
      component="p"
      className='text-left'
      style={{
        borderLeft: line.startsWith('> ') ? '2px solid #ddd' : 'none',
        color: line.startsWith('> ') ? '#555' : 'inherit',
      }}
    >
      {line}
    </Typography>
  ));
};

const TimelineDiscord: React.FC<TimelineDiscordProps> = ({ event, isBelowMdScreen }) => {
  const fromArray = Array.isArray(event.from) ? event.from : [event.from];
  const toArray = Array.isArray(event.to) ? event.to : [event.to];

  return (
    <Card className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root border discord">
      <CardContent className="MuiCardContent-root flex is-full gap-4">
        {fromArray.map((sender, index) => (
          <Avatar
            key={index}
            className="MuiAvatar-root MuiAvatar-circular"
            src={`/images/avatars/${sender.avatar}`}
            alt={sender.name}
            style={{ width: isBelowMdScreen ? 70 : 70, height: isBelowMdScreen ? 70 : 70 }}
          />
        ))}
        <Box className="flex flex-col grow">
          <div className="flex items-center justify-between flex-wrap gap-x-4 gap-y-2">
            <div className="flex flex-col">
              {fromArray.map((sender, index) => (
                <Typography key={index} variant="body1" className="mui-cb9j9j text-left">
                  <strong>From: </strong>{sender.name}
                </Typography>
              ))}
              <div className='text-left'>
                <strong>To:</strong> 
                {toArray.map((recipient, index) => (
                  <Typography key={index} variant="body2" className="mui-cb9j9j text-left">
                    {recipient.name}
                  </Typography>
                ))}
              </div>
            </div>
            <div className="flex items-left">
              <Typography variant="body1" className="mui-miu7e">
                {new Date(event.timestamp_epoc * 1000).toLocaleString()}
              </Typography>
            </div>
          </div>
        </Box>
      </CardContent>
      <CardContent className="message" style={{ maxHeight: '300px', overflowY: 'auto' }}>
        {parseDiscordMessage(event.message)}
      </CardContent>
    </Card>
  );
};

export default TimelineDiscord;