import React from 'react';
import { Card, CardContent, Typography, Avatar, Divider } from '@mui/material';
import { Email } from './types';

type TimelineMailProps = {
  event: Email;
  isBelowMdScreen: boolean;
};

const parseEmailBody = (body: string) => {
  const lines = body.split('\n');
  let isQuoted = false;

  return lines.map((line, index) => {
    if (/^On .* wrote:/.test(line) || line.startsWith('> ')) {
      isQuoted = true;
    }

    if (!line.trim()) {
      isQuoted = false;
    }

    return (
      <Typography
        key={index}
        variant="body2"
        component="div"
        style={{
          paddingLeft: isQuoted ? 20 : 0,
          borderLeft: isQuoted ? '2px solid #ddd' : 'none',
          marginLeft: isQuoted ? 10 : 0,
          color: isQuoted ? '#555' : 'inherit',
        }}
      >
        {line}
      </Typography>
    );
  });
};

const formatContactInfo = (contacts: { name: string; email: string }[]) => {
  return contacts.map(contact => `${contact.name} <${contact.email}>`).join(', ');
};

const ensureArrayOfObjects = (field: any): { name: string; email: string; avatar: string }[] => {
  if (Array.isArray(field)) {
    return field.map(contact => {
      if (typeof contact === 'string') {
        const match = contact.match(/(.*) <(.*)>/);
        if (match) {
          return { name: match[1], email: match[2], avatar: '' };
        }
      }
      return contact;
    });
  }
  return [];
};

const TimelineMail: React.FC<TimelineMailProps> = ({ event, isBelowMdScreen }) => {
  const from = ensureArrayOfObjects(event.from);
  const to = ensureArrayOfObjects(event.to);
  const cc = ensureArrayOfObjects(event.cc);
  const bcc = ensureArrayOfObjects(event.bcc);

  return (
    <Card className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root border mui-gkyr07 email">
      <CardContent className="MuiCardContent-root flex is-full gap-4 mui-2ggi8u">
        <Avatar
          className="MuiAvatar-root MuiAvatar-circular mui-1o9xkzw"
          src={`/images/avatars/${from[0]?.avatar}`}
          alt={from[0]?.name}
          style={{ width: isBelowMdScreen ? 70 : 70, height: isBelowMdScreen ? 70 : 70 }}
        />
        <div className="flex items-center justify-between flex-wrap grow gap-x-4 gap-y-2">
          <div className="flex flex-col text-left">
            <Typography variant="body1" className="mui-cb9j9j"><strong>Subject: </strong> <small>{event.subject}</small></Typography>
            <Typography variant="body2" className="mui-cb9j9j"><strong>From:</strong> <small>{formatContactInfo(from)}</small></Typography>
            <Typography variant="body2" className="mui-14nabqt"><strong>To:</strong> <small>{formatContactInfo(to)}</small></Typography>
            {cc.length > 0 && (
              <Typography variant="body2" className="mui-14nabqt"><strong>Cc:</strong> <small>{formatContactInfo(cc)}</small></Typography>
            )}
            {bcc.length > 0 && (
              <Typography variant="body2" className="mui-14nabqt"><strong>Bcc:</strong> <small>{formatContactInfo(bcc)}</small></Typography>
            )}
          </div>
          <div className="flex items-center gap-4">
            <Typography variant="body1" className="mui-miu7e">
              {new Date(event.date).toLocaleString()}
            </Typography>
          </div>
        </div>
      </CardContent>
      <Divider className="MuiDivider-root MuiDivider-fullWidth mui-zdzcbx" />
      <CardContent className="MuiCardContent-root mui-2ggi8u" style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <div className="text-textSecondary styles_message__LkVz3 text-left">
          {parseEmailBody(event.body)}
        </div>
      </CardContent>
    </Card>
  );
};

export default TimelineMail;