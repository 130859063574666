import { Email, CalendarEvent, SlackMessage, DiscordMessage, Event } from './types';
import contextData from './data/context.json';

const isValidDate = (date: Date) => !isNaN(date.getTime());

const loadJsonFile = async (filePath: string) => {
  try {
    const module = await import(`${filePath}`);
    return module.default;
  } catch (error) {
    console.warn(`Could not load ${filePath}:`, error);
    return [];
  }
};

const parseDiscordTimestamp = (timestamp: string): number | null => {
  try {
    const parsedTimestamp = Date.parse(timestamp);
    return isNaN(parsedTimestamp) ? null : parsedTimestamp;
  } catch (error) {
    console.error(`Error parsing Discord timestamp: ${timestamp}`, error);
    return null;
  }
};

const formatTimestamp = (timestamp: string | number | null): string => {
  if (typeof timestamp === 'string') {
    const date = parseDiscordTimestamp(timestamp);
    if (date !== null) {
      return new Date(date).toLocaleString();
    }
  } else if (typeof timestamp === 'number') {
    if (Number.isInteger(timestamp)) {
      return new Date(timestamp).toLocaleString();
    } else {
      return new Date(timestamp * 1000).toLocaleString();
    }
  }
  return 'Invalid Date';
};

// type ContactEntry = {
//   avatar: string;
//   shortname: string[];
//   name: string[];
//   email?: string[];
//   discord?: string[];
//   role?: string;
//   organization?: string;
// };

class TimelineData {
  static async loadEvents(): Promise<Event[]> {
    const platforms = contextData.platforms;

    const ignoredEmails = contextData.ignore.email; // Updated to directly access the email array

    const loadPlatformData = async (platform: string, path: string) => {
      const data = await loadJsonFile(path);
      if (platform === 'email') {
        return data.filter((email: Email) => !email.from.some(sender => ignoredEmails.includes(sender.email.toLowerCase()))) as Email[];
      }
      if (platform === 'calendar') {
        return data.filter((event: CalendarEvent) => !event.from.some(sender => ignoredEmails.includes(sender.email.toLowerCase()))) as CalendarEvent[];
      }
      if (platform === 'slack') {
        return data as SlackMessage[];
      }
      if (platform === 'discord') {
        return data as DiscordMessage[];
      }
      return [];
    };

    const emailData = platforms.includes('email') ? await loadPlatformData('email', './data/email.json') : [];
    const calendarData = platforms.includes('calendar') ? await loadPlatformData('calendar', './data/calendar.json') : [];
    const slackData = platforms.includes('slack') ? await loadPlatformData('slack', './data/slack.json') : [];
    const discordData = platforms.includes('discord') ? await loadPlatformData('discord', './data/discord.json') : [];

    const combinedEvents: Event[] = [
      ...(emailData.map((event) => ({ ...event, type: 'email' as const })) as Email[]),
      ...(calendarData.map((event) => ({ ...event, type: 'calendar' as const })) as CalendarEvent[]),
      ...(slackData.map((event) => ({ ...event, type: 'slack' as const })) as SlackMessage[]),
      ...(discordData.map((event) => ({ ...event, type: 'discord' as const })) as DiscordMessage[]),
    ];

    const filteredAndSortedEvents = combinedEvents
      .filter((event) => {
        const timestamp = getEventTimestamp(event);
        return isValidDate(new Date(timestamp as string | number));
      })
      .sort((a, b) => {
        const dateA = getEventTimestamp(a);
        const dateB = getEventTimestamp(b);

        if (dateA === null) return 1;
        if (dateB === null) return -1;

        return (dateB as number) - (dateA as number);
      });

    return filteredAndSortedEvents;
  }
}

const getEventTimestamp = (event: Event): number | null => {
  if (event.type === 'email') {
    return event.timestamp_epoc ?? new Date(event.date).getTime();
  }
  if (event.type === 'calendar') {
    return event.timestamp_epoc ?? new Date(event.start).getTime();
  }
  if (event.type === 'slack') {
    return event.timestamp_epoc ?? parseFloat(event.timestamp) * 1000;
  }
  if (event.type === 'discord') {
    return event.timestamp_epoc ?? parseDiscordTimestamp(event.timestamp);
  }
  return null;
};

export { TimelineData, formatTimestamp, parseDiscordTimestamp };