import React from 'react';
import { Chip, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconMail, IconCalendar } from '@tabler/icons-react';
import CancelIcon from '@mui/icons-material/Cancel';
import { EventType } from './types'; // Ensure this matches your type definition

interface EventTypeChipProps {
  eventType: EventType;
  onDelete: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const TimelineDot = styled('div')<{ color: string }>(({ color }) => ({
  width: 20,
  height: 20,
  borderRadius: '50%',
  backgroundColor: color,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const EventTypeChip: React.FC<EventTypeChipProps> = ({ eventType, onDelete }) => {
  let color: string;
  let icon: React.ReactNode;

  switch (eventType) {
    case 'email':
      color = 'primary';
      icon = <IconMail size={20} />;
      break;
    case 'calendar':
      color = 'error';
      icon = <IconCalendar size={20} />;
      break;
    case 'slack':
      color = 'info';
      icon = <img src={'/images/logos/slack.png'} alt="Slack" style={{ width: 20, height: 20 }} />;
      break;
    case 'discord':
      color = 'secondary';
      icon = <img src={'/images/logos/discord.png'} alt="Discord" style={{ width: 20, height: 20 }} />;
      break;
    default:
      color = 'default';
      icon = null;
  }

  return (
    <Chip
      avatar={<TimelineDot color={color}>{icon}</TimelineDot>}
      label={eventType}
      onDelete={onDelete}
      deleteIcon={<IconButton aria-label="delete" style={{ padding: 8 }}><CancelIcon /></IconButton>}
      style={{ color: 'white', backgroundColor: '#020203'}}
    />
  );
};

export default EventTypeChip;