import React from 'react';
import { Card, CardContent, Typography, Avatar, Divider } from '@mui/material';
import { CalendarEvent } from './types';

type TimelineCalendarProps = {
  event: CalendarEvent;
  isBelowMdScreen: boolean;
};

const parseDescription = (description: string) => {
  const lines = description.split('\n');
  return lines.map((line, index) => (
    <Typography key={index} variant="body2" component="div">
      {line}
    </Typography>
  ));
};

const TimelineCalendar: React.FC<TimelineCalendarProps> = ({ event, isBelowMdScreen }) => (
  <Card className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root border mui-gkyr07 calendar">
    <CardContent className="MuiCardContent-root flex is-full gap-4 mui-2ggi8u">
      <Avatar
        className="MuiAvatar-root MuiAvatar-circular mui-1o9xkzw"
        src={`/images/avatars/${event.from_avatar}`}
        alt={event.organizer.name}
        style={{ width: isBelowMdScreen ? 70 : 70, height: isBelowMdScreen ? 70 : 70 }}
      />
      <div className="flex items-center justify-between flex-wrap grow gap-x-4 gap-y-2">
        <div className="flex flex-col">
          <Typography variant="body1" className="mui-cb9j9j">{event.organizer.name}</Typography>
          <Typography variant="body2" className="mui-14nabqt">{event.organizer.name}</Typography>
        </div>
        <Typography variant="body1" className="mui-miu7e">
          {new Date(event.start).toLocaleString()}
        </Typography>
      </div>
    </CardContent>
    <Divider className="MuiDivider-root MuiDivider-fullWidth mui-zdzcbx" />
    <CardContent className="MuiCardContent-root mui-2ggi8u text-left" style={{ maxHeight: '300px', overflowY: 'auto' }}>
      <Typography variant="h5" className="mbe-4">{event.summary}</Typography>
      <Typography className="mbe-3"><strong>Location:</strong> {event.location}</Typography>
      <Typography className="mbe-3"><strong>Description:</strong></Typography>
      <div className="text-textSecondary styles_message__LkVz3 float-left">
        {parseDescription(event.description)}
      </div>
      <Typography className="mbe-3"><strong>Attendees:</strong></Typography>
      <ul>
        {event.attendees.map((attendee, idx) => (
          <li key={idx}>{attendee.name}</li>
        ))}
      </ul>
    </CardContent>
  </Card>
);

export default TimelineCalendar;