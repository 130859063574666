import React, { useEffect, useState } from 'react';
import { Box, Autocomplete, TextField, Checkbox, Avatar, ListItemText, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import contextData from './data/context.json'; // Ensure this is correctly pointing to your data
import { Contact, EventType } from './types';
import EventTypeChip from './EventTypeChip'; // Import the custom chip component

interface TimelineFilterProps {
  selectedContacts: Contact[];
  selectedEventTypes: EventType[]; // Use EventType here
  onSelectContacts: (contacts: Contact[]) => void;
  onSelectEventTypes: (eventTypes: EventType[]) => void; // Ensure this expects an array of EventType
}

const FilterContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  width: '300px',
  height: '100vh',
  backgroundColor: theme.palette.background.default, // Dark mode background
  color: theme.palette.text.primary, // White text for dark mode
  zIndex: 1000,
  padding: theme.spacing(2),
  borderLeft: `1px solid ${theme.palette.divider}`,
  transform: 'translateX(100%)', // Initially hidden
  transition: 'transform 0.3s ease-in-out', // Smooth transition
  '&.active': {
    transform: 'translateX(0)', // Slide in when active
  },
  '& .filter-content': {
    paddingTop: theme.spacing(8), // Add top padding to avoid overlap with filter button
  }
}));

const FilterButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  top: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 1100,
  color: 'white', // Set button color to white
  transition: 'color 0.3s ease-in-out', // Smooth transition for color change
  '&.active': {
    color: 'black', // Change color to black when active
  },
}));

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    color: 'white', // Always white color for both unchecked and checked states
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      color: 'white', // Ensure the checked icon is also white
    },
  },
}));

const TimelineFilter: React.FC<TimelineFilterProps> = ({ selectedContacts, selectedEventTypes, onSelectContacts, onSelectEventTypes }) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [eventTypes, setEventTypes] = useState<EventType[]>([]);
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  useEffect(() => {
    const validContacts = contextData.contacts as Contact[];
    setContacts(validContacts);

    // Extract unique event types if available in contextData
    const types = Array.from(new Set(contextData.platforms || [])) as EventType[];
    setEventTypes(types);
  }, []);

  // const handleDeleteChip = (contactToDelete: Contact) => (event: React.MouseEvent<HTMLButtonElement>) => {
  //   onSelectContacts(selectedContacts.filter(contact => contact.email !== contactToDelete.email));
  // };

  const handleDeleteEventType = (eventTypeToDelete: EventType) => () => {
    onSelectEventTypes(selectedEventTypes.filter(type => type !== eventTypeToDelete));
  };

  const toggleFilterMenu = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  return (
    <>
      <FilterButton onClick={toggleFilterMenu} className={isFilterVisible ? 'active' : ''}>
        <FilterListIcon />
      </FilterButton>
      <FilterContainer className={isFilterVisible ? 'active' : ''}>
        <Box className="filter-content">
          <Box display="flex" alignItems="center" mb={2}>
            <Autocomplete
              multiple
              options={eventTypes}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              value={selectedEventTypes}
              onChange={(event, newValue) => {
                onSelectEventTypes(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderOption={(props, option) => (
                <li {...props} style={{ display: 'flex', alignItems: 'center', backgroundColor: '#020203', padding: '8px 12px' }}>
                  <EventTypeChip
                    eventType={option}
                    onDelete={handleDeleteEventType(option)}
                  />
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Filter by Event Types" placeholder="Search event types" />
              )}
              renderTags={(value, getTagProps) => (
                value.map((eventType, index) => {
                  const tagProps = getTagProps({ index });
                  return (
                    <EventTypeChip
                      eventType={eventType}
                      {...tagProps}
                      onDelete={() => handleDeleteEventType(eventType)()}
                    />
                  );
                })
              )}
              style={{ width: '100%' }}
            />
          </Box>

          <Box display="flex" alignItems="center">
            <Autocomplete
              multiple
              options={contacts}
              disableCloseOnSelect
              getOptionLabel={(option: Contact) => option.name.join(' ')}
              value={selectedContacts}
              onChange={(event: React.SyntheticEvent, newValue: Contact[] | null) => {
                onSelectContacts(newValue || []);
              }}
              inputValue={inputValue}
              onInputChange={(event: React.SyntheticEvent, newInputValue: string) => {
                setInputValue(newInputValue);
              }}
              renderOption={(props: React.HTMLProps<HTMLLIElement>, option: Contact, state: { selected: boolean }) => {
                const { key, ...otherProps } = props; // Extract key and otherProps
                return (
                  <li
                    {...otherProps}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: '#020203',
                      padding: '8px 12px', // Add padding for spacing
                    }}
                  >
                    <CustomCheckbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 12 }} // Adjust margin for spacing
                      checked={state.selected}
                    />
                    <Avatar
                      src={`/images/avatars/${option.avatar}`}
                      alt={option.name.join(' ')}
                      style={{
                        marginRight: 12, // Space between avatar and text
                        width: 40, // Adjust size as needed
                        height: 40, // Adjust size as needed
                      }}
                    />
                    <ListItemText
                      primary={option.name.join(' ')}
                      primaryTypographyProps={{
                        style: {
                          backgroundColor: '#020203',
                          color: 'white',
                        },
                      }}
                    />
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Filter by Contacts"
                  placeholder="Search contacts"
                />
              )}
              style={{ width: '100%' }}
            />
          </Box>
        </Box>
      </FilterContainer>
    </>
  );
};

export default TimelineFilter;